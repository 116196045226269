<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7676 1.74023C8.93102 1.74023 1.76758 8.90368 1.76758 17.7402C1.76758 26.5768 8.93102 33.7402 17.7676 33.7402C26.6041 33.7402 33.7676 26.5768 33.7676 17.7402"
      stroke="#292F33"
      stroke-width="2.5"
      stroke-linecap="round"
    />
  </svg>
</template>
