<template>
  <div class="Loader__animation">
    <IconLoader />
  </div>
</template>
<style scoped lang="scss">
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Loader {
  &__animation {
    animation: spin 1s linear infinite;
    width: 30px;
    height: 30px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
